import { Environment } from './model';

export const environment: Environment = {
  production: false,
  isMedical: true,
  firebase: {
    apiKey: 'AIzaSyDnZiEnTk4DONLS291mh5Zyt5bvTFAT1vo',
    authDomain: 'remedee-medical-dev.firebaseapp.com',
    projectId: 'remedee-medical-dev',
    storageBucket: 'remedee-medical-dev.appspot.com',
    messagingSenderId: '278614007999',
    appId: '1:278614007999:web:603208d1990b687a7e260a',
    measurementId: 'G-GKTVTXMCE9',
  },
  functionsRegion: 'europe-west1',
  functionsBaseUrl:
    'https://europe-west1-remedee-medical-dev.cloudfunctions.net',
  reportBaseUrl: 'https://report-dev.remedee.com',
  version: '3.1.0',
  env: 'STAGING',
  useEmulators: false,
};
