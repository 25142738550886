import * as Sentry from '@sentry/angular';
import { environment } from '@env';

function isEnabled() {
  const hostname = window.location.hostname;
  return (
    ['STAGING', 'PRODUCTION'].includes(environment.env) && // but not CI
    !['localhost', '127.0.0.1'].includes(hostname)
  );
}

export function initSentry() {
  Sentry.init({
    dsn: 'https://7beec9cae173497fb56ed3b6b2bf3481@o4504690115870720.ingest.sentry.io/4504690121113600',
    enabled: isEnabled(),
    environment: environment.env,
    release: environment.version,
  });
}
