import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { Router } from '@angular/router';
import { AuthService } from '@core/firebase/auth';
import { environment } from '@env';

@Component({
  standalone: true,
  selector: 'app-sign-in',
  imports: [MatCardModule, MatButtonModule],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInPageComponent {
  readonly environment = environment.env;
  readonly version = environment.version;

  constructor(private authService: AuthService, private router: Router) {}

  async googleAuth() {
    const user = await this.authService.signIn();
    if (user) {
      this.router.navigate(['main/dashboard']);
    }
  }
}
