export enum AnalyticsEvents {
  /** Admin events */
  ADMIN_CREATE_COACH = 'ADMIN_CREATE_COACH',
  ADMIN_UPDATE_COACH = 'ADMIN_UPDATE_COACH',
  ADMIN_ASSIGN_TO_COACH = 'ADMIN_ASSIGN_TO_COACH',
  ADMIN_ADD_VACATION = 'ADMIN_ADD_VACATION',
  ADMIN_UPDATE_PREDEFINED_MESSAGE = 'ADMIN_UPDATE_PREDEFINED_MESSAGE',
  ADMIN_ASSIGN_COACHES = 'ADMIN_ASSIGN_COACHES',

  /** Coaching events */
  CHANGE_PAGE = 'CHANGE_PAGE',
  EDIT_USER_STAGE = 'EDIT_USER_STAGE',
  EDIT_USER_TAGS = 'EDIT_USER_TAGS',
  SEND_MESSAGE = 'SEND_MESSAGE',

  /** Report events */
  REPORT_OPEN = 'REPORT_OPEN',

  /** Survey events */
  SURVEY_OPEN = 'SURVEY_OPEN',
  SURVEY_PAGE = 'SURVEY_PAGE',
  SURVEY_SUBMIT = 'SURVEY_SUBMIT',

  /** Misc */
  ELIGIBILITY = 'ELIGIBILITY',
}

/**
 * Analytics event parameters used when a coach sends 1 or several chat messages,
 * to 1 or several users.
 */
export interface SendMessageEvent {
  /**
   * Total number of messages sent.
   * Example: if the coach selects 2 clients and perform a step with 3 messages, total will be 2x3 = 6 messagesCount.
   */
  messagesCount: number;
  /** Number of different users receiving the messages. */
  usersCount: number;
  /** Total number of reports shared by this chat action. */
  reportsCount?: number;
}

export interface EditUserTagsEvent {
  usersCount: number;
  addTags: string[];
  removeTags: string[];
}

export interface EditUserStageEvent {
  usersCount: number;
  programStage: string;
}

export interface ChangePageEvent {
  type: 'tab' | 'page';
  value: number;
  label: string;
}

export interface ReportEvent {
  reference: string;
}

export interface SurveyEvent {
  reference: string;
  version: string;
  isFinished: boolean;
  page: number;
  pageTitle: string;
}

export interface EligibilityEvent {
  result: string;
  uid?: string;
}
